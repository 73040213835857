var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "kuisioner-2",
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Formulir Gizi',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "bg": "#FFF",
      "p": ['1rem', '0px']
    }
  }, [_c('Steps', {
    attrs: {
      "current": 2,
      "total": 6
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px",
      "mx": "auto",
      "margin-top": ['16px', '24px']
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "text-transform": "uppercase",
      "font-weight": "500",
      "font-size": ['16px', '18px'],
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Formulir Gizi (2/6) ")]), _c('c-heading', {
    attrs: {
      "font-weight": "700",
      "font-size": ['18px', '28px'],
      "line-height": ['27px', '42px'],
      "color": "black.900"
    }
  }, [_vm._v(" Data Antropometri (Ukuran & Komposisi Tubuh) ")])], 1), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "marginTop": "24px"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.weight.$invalid,
      "label": "Berat Badan (kg)",
      "placeholder": "Masukkan Berat Badan",
      "invalid-text": _vm.$v.weight.minValue ? _vm.$v.weight.maxValue ? '' : 'Berat Badan tidak boleh lebih dari 250 kg' : 'Berat Badan tidak boleh kurang dari 0 kg'
    },
    model: {
      value: _vm.weight,
      callback: function callback($$v) {
        _vm.weight = $$v;
      },
      expression: "weight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.height.$invalid,
      "label": "Tinggi Badan (cm)",
      "placeholder": "Masukkan Tinggi Badan",
      "invalid-text": _vm.$v.height.minValue ? _vm.$v.height.maxValue ? '' : 'Tinggi Badan tidak boleh lebih dari 250 cm' : 'Tinggi Badan tidak boleh kurang dari 0 cm'
    },
    model: {
      value: _vm.height,
      callback: function callback($$v) {
        _vm.height = $$v;
      },
      expression: "height"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-invalid": _vm.$v.waistSize.$invalid,
      "label": "Lingkar Pinggang (cm)",
      "placeholder": "Masukkan Lingkar Pinggang",
      "invalid-text": _vm.$v.waistSize.minValue ? _vm.$v.waistSize.maxValue ? '' : 'Lingkar Pinggang tidak boleh lebih dari 150 cm' : 'Lingkar Pinggang tidak boleh kurang dari 0 cm'
    },
    model: {
      value: _vm.waistSize,
      callback: function callback($$v) {
        _vm.waistSize = $$v;
      },
      expression: "waistSize"
    }
  }), _c('FormAccordionList', [_c('FormAccordionListItem', {
    attrs: {
      "label": "Hasil Pengukuran Komposisi Tubuh",
      "is-error": _vm.isSectionHasilPengukuranValid
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-invalid": _vm.$v.fat.$invalid,
      "label": "Persen Lemak (Body Fat Percentage) (%)",
      "placeholder": "Persen Lemak (Body Fat Percentage)",
      "invalid-text": _vm.$v.fat.minValue ? _vm.$v.fat.maxValue ? '' : 'Persen Lemak tidak boleh lebih dari 50%' : 'Persen Lemak tidak boleh kurang dari 0%'
    },
    model: {
      value: _vm.fat,
      callback: function callback($$v) {
        _vm.fat = $$v;
      },
      expression: "fat"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-invalid": _vm.$v.muscleMass.$invalid,
      "label": "Massa Otot (Muscle Mass) (kg)",
      "placeholder": "Masukkan Massa Otot (Muscle Mass)",
      "invalid-text": _vm.$v.muscleMass.minValue ? _vm.$v.muscleMass.maxValue ? '' : 'Massa Otot tidak boleh lebih dari 200 kg' : 'Massa Otot tidak boleh kurang dari 0 kg'
    },
    model: {
      value: _vm.muscleMass,
      callback: function callback($$v) {
        _vm.muscleMass = $$v;
      },
      expression: "muscleMass"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-invalid": _vm.$v.visceralFat.$invalid,
      "label": "Lemak Visceral (Visceral Fat)",
      "placeholder": "Masukkan Lemak Visceral (Visceral Fat)",
      "invalid-text": _vm.$v.visceralFat.minValue ? _vm.$v.visceralFat.maxValue ? '' : 'Lemak Visceral tidak boleh lebih dari 70' : 'Lemak Visceral tidak boleh kurang dari 0'
    },
    model: {
      value: _vm.visceralFat,
      callback: function callback($$v) {
        _vm.visceralFat = $$v;
      },
      expression: "visceralFat"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "gap": "3",
      "margin-top": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "w": "100%",
      "h": "48px",
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "100px"
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "type": "submit",
      "w": "100%",
      "h": "48px",
      "border-radius": "100px",
      "variant-color": "primary",
      "variant": "solid",
      "loading-text": "Submitting",
      "is-disabled": _vm.$v.$invalid,
      "is-loading": _vm.isSubmitting
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }