var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-accordion', {
    attrs: {
      "allow-toggle": true,
      "default-index": [],
      "allow-multiple": false,
      "d": "flex",
      "flex-direction": "column",
      "gap": "20px",
      "mb": "20px"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }