var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "mb": _vm.isWithoutMarginBottom ? '0' : ['16px', '24px'],
      "is-read-only": _vm.isReadOnly,
      "w": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1), _vm.type == 'textarea' ? _c('c-textarea', {
    attrs: {
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": _vm.value ? '500' : '400',
      "placeholder": _vm.placeholder ? _vm.placeholder : _vm.label
    },
    on: {
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }) : _vm.type === 'date' ? _c('c-box', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "height": ['48px', '62px']
    }
  }, [_c('DatePicker', {
    attrs: {
      "input-class": {
        default: true,
        err: _vm.isInvalid
      },
      "value-type": "YYYY-MM-DD",
      "format": "DD MMMM YYYY",
      "type": "date",
      "placeholder": _vm.placeholder,
      "clearable": false,
      "disabled": _vm.isDisabled,
      "editable": "false",
      "lang": "id",
      "disabled-date": function disabledDate(date) {
        return date > new Date();
      }
    },
    on: {
      "blur": _vm.onBlur
    },
    scopedSlots: _vm._u([{
      key: "icon-calendar",
      fn: function fn() {
        return [_vm._v("   ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  })], 1) : _c('c-input-group', [_vm.leftElement || _vm.iconLeftElement ? _c('c-input-left-element', {
    attrs: {
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": _vm.value ? '500' : '400',
      "h": "100%"
    }
  }, [_vm._v(" " + _vm._s(_vm.iconLeftElement ? "" : _vm.leftElement) + " "), _vm.iconLeftElement ? _c('c-box', {
    attrs: {
      "h": ['20px', '25px'],
      "w": ['20px', '25px'],
      "min-h": ['20px', '25px'],
      "min-w": ['20px', '25px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconLeftElement,
      "height": "100%",
      "width": "100%",
      "fill": _vm.iconLeftElementColor
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('c-input', {
    attrs: {
      "type": _vm.type_ || 'text',
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": _vm.value ? '500' : '400',
      "placeholder": _vm.placeholder ? _vm.placeholder : _vm.label
    },
    on: {
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }), _vm.rightElement || _vm.iconRightElement || _vm.type === 'password' ? _c('c-input-right-element', {
    attrs: {
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": _vm.value ? '500' : '400',
      "h": "100%",
      "mx": "1rem",
      "cursor": _vm.type === 'password' ? 'pointer' : 'auto'
    }
  }, [_vm._v(" " + _vm._s(_vm.iconRightElement ? "" : _vm.rightElement) + " "), _c('c-text', {
    attrs: {
      "as": "span",
      "h": ['20px', '25px'],
      "w": ['20px', '25px'],
      "min-h": ['20px', '25px'],
      "min-w": ['20px', '25px']
    }
  }, [_vm.iconRightElement ? _c('inline-svg', {
    attrs: {
      "src": _vm.iconRightElement,
      "height": "100%",
      "width": "100%",
      "fill": "#323232"
    }
  }) : _vm._e(), _vm.type === 'password' && _vm.type_ === 'password' ? _c('inline-svg', {
    attrs: {
      "src": require('@/assets/ic-show-password.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#323232",
      "user-select": "none"
    },
    on: {
      "click": _vm.toggleShowPassword
    }
  }) : _vm._e(), _vm.type === 'password' && _vm.type_ === 'text' ? _c('inline-svg', {
    attrs: {
      "src": require('@/assets/ic-hidden-password.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#323232",
      "user-select": "none"
    },
    on: {
      "click": _vm.toggleShowPassword
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "d": "flex",
      "gap": "8px",
      "align-items": "center",
      "color": _vm.helperTextColor
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": _vm.hasHelperTextClick ? 'pointer' : 'auto'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('helper-text-click');
      }
    }
  }, [_vm.helperTextLogo ? _c('c-box', {
    attrs: {
      "min-height": ['13px', '16px'],
      "min-width": ['13px', '16px'],
      "height": ['13px', '16px'],
      "width": ['13px', '16px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.helperTextLogo,
      "height": "100%",
      "width": "100%",
      "fill": _vm.helperTextColor
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.helperText) + " ")], 1)], 1) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "d": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey
    }, [_vm._v(" ● " + _vm._s(invalidTextValue) + " ")]);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }